import { Component, Output, EventEmitter } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import {
    ExcelExportService,
    CommonConstant
} from "projects/common/src/public-api";
import { NgxSpinnerService } from "ngx-spinner";
import { StudentService } from "@student-core";
import { Student } from "models";

@Component({
    templateUrl: "./fee.component.html",
    styleUrls: ["fee.component.scss"],
})
export class StudentFeeComponent {
    studentData: Student[] = [];
    loading: boolean;
    search: { [key: string]: any } = {
        orderBy: "rollNbr",
        direction: "desc",
        size: CommonConstant.defaultPageSize,
        page: 0,
    };
    total: number;

    banner2 = [
        { studentProfileId: "Airbnb", fullName: 'assets/images/brand1.png', admissionNumber: "1111" },
        { studentProfileId: "Airbnb", fullName: 'assets/images/brand1.png', admissionNumber: "1111" },
    ];

    @Output()
    change: EventEmitter<any> = new EventEmitter();

    constructor(
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private exportService: ExcelExportService,
        private spinner: NgxSpinnerService,
        private studentService: StudentService
    ) {
    }

    ngOnInit() {
        this.getAllStudentData();
    }

    private getAllStudentData() {
        this.loading = true;
        this.spinner.show();
        let searchParams = Object.assign(this.search, {});
        this.studentService.allStudent(searchParams).subscribe((result) => {
            this.total = result.totalElements;
            this.studentData = result.content;
            this.loading = false;
            this.spinner.hide();
        }, (error) => {
            this.loading = false;
            this.spinner.hide();
        });
    }

    goToDashboard(item: any) {
        this.router.navigate(["..", "dashboard", item.studentProfileId], {
            relativeTo: this.activatedRoute,
        });
    }

    onSelect({ selected }) {
        this.goToDashboard(selected[0]);
    }

    setPage(pageInfo) {
        this.search.page = pageInfo.offset;
        this.getAllStudentData();
        //this.getAllEmployeeData();
    }

    onSort(event) {
        const sort = event.sorts[0];
        this.search.orderBy = sort.prop;
        this.search.direction = sort.dir;
        this.setPage({ offset: 0 });
    }

    filterChanged() {
        this.setPage({ offset: 0 });
    }



    download() {
        const searchParams = Object.assign(this.search, {
            size: this.total,
            page: 0,
        });
        this.studentService.allStudent(searchParams).subscribe((result) => {
            const data: any[] = result.content.map((x, index) => {
                const temp = {
                    "S.No": index + 1,
                };
                return temp;
            });
            this.exportService.exportAsExcelFile(data, "Student Data");
        });
    }
}
