import { Component, OnInit } from '@angular/core';
import { AppService, RoleService, UserAuthService } from 'projects/common/src/public-api';
import { Location } from '@angular/common';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

	constructor(
		private authService: UserAuthService,
		private appService: AppService,
		private roleService: RoleService) {
	}

	ngOnInit() {
		// this.getToken();
		this.getSecurityGroups();
		this.getColumnConfig();
	}

	private getToken() {
		const urlParams = new URLSearchParams(window.location.search);
		const token = urlParams.get('token');
		if (token) {
			this.authService.saveToken(token);
		}
	}

	private getColumnConfig() {
		this.appService.datatableSetup('STUDENT');
	}

	private getSecurityGroups() {
		this.roleService.getSecurityGroups('Student');
	}
}
