<section>
    <div class="container-fluid" >
        <div class="app-box filter-panel">
            <div class="col-sm-7 col-12 d-inline-flex">
                <div class="container">
                    <div class="select-bar">
                        <select>
                            <option>Class</option>
                            <option>Class iXth B</option>
                        </select>
                        <select>
                            <option>Section</option>
                            <option>A</option>
                        </select>
                    </div>
                </div>
            </div>
        </div>
        <div class="app-box mb-5">
            <div class="row title-row">
                <div class="col-sm-3">
                    <span class="title">Student List</span>
                </div>
                <div class="col">
                </div>
            </div>

        </div>
    </div>
</section>
