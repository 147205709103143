import { environment } from "../../../environments/environment";

const apiPath = environment.apiPath;

export const APIConstant = {
    basePath: apiPath,

    // Student
    student: `${apiPath}/students/`,
    allStudent: `${apiPath}/students/search/datatable`,
    //allStudent: `${apiPath}/security/users`,
    searchStudent: `${apiPath}/students/search`,
    studentBasicInfo: `${apiPath}/students/basicInfo`,
    studentFeedback: `${apiPath}/students/feedback/`,
    studentContactInfo: `${apiPath}/students/contact`,
    studentDrop: `${apiPath}/students/drop`,
    studentMissingFields: `${apiPath}/students/data/fields`,
    studentMissingIfoReport: `${apiPath}/students/missingInfo`,
    studentViewAllReport: `${apiPath}/students/report`,
    studentImport: `${apiPath}/students/import`,
    birthdays: `${apiPath}/students/birthdays`,
    postInfo:`${apiPath}/students/search-data`,
    checkRollnumber: `${apiPath}/students/checkRollNumber`,
    getRelations:`${apiPath}/students/relation/fields`,



    searchEmployee: `${apiPath}/hr/employees/search`,
    studentDropOut: `${apiPath}/students/dropped`,
    studentStrength: `${apiPath}/students/strength-report`,
    profileType: `${apiPath}/school/profile-type`,

    stats: `${apiPath}/attendance/stats`,
    feesStat: `${apiPath}/finance/fees/stats`,
    attendance: `${apiPath}/attendance`,
    documents: `${apiPath}/documents/get`,
    houseMappings: `${apiPath}/academics/house-mapping`,
    categoryMapping: `${apiPath}/finance/fees/category-mapping`,
    studentClasses:`${apiPath}/students/classes`,
    exams: `${apiPath}/academics/exams`,
    marksByStudent: `${apiPath}/academics/marks/byStudent`,
    submitImportCSVData: `${apiPath}/students/import`,
    progressReport: `${apiPath}/academics/reportCard/progressReport`,


    assignmentReports: `${apiPath}/lms/assignments/students/status-report`,
    studentsAssignmentReports: `${apiPath}/lms/assignments/students/subject-wise-status-report`,
    studentSubjectwiseMarksReport: `${apiPath}/lms/assignments/students/subject-wise-marks-report`,
    studentAssignmentGradeReport: `${apiPath}/lms/assignments/students/grade-report`,
    studentSubjectwiseAssignmentGradeReport: `${apiPath}/lms/assignments/students/subject-wise-grade-report`,
    activateStudent: `${apiPath}/students/activate`,
    syncDataWithMagento: `${apiPath}/students/data-sync`,
};

export const PublicAPI = [];
