import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import {
  AppCommonModule, ResponseInterceptor, AuthInterceptor, AppConfigModule
} from 'projects/common/src/public-api';
import { ValidateComponent } from './validate/validate.component';
import { CoreModule, PublicAPI, APIConstant } from '@student-core';
import {StudentFeeComponent} from './screens/student/fee/fee.component';
import { NguCarouselModule } from '@ngu/carousel';
import 'hammerjs';
@NgModule({
  declarations: [
    AppComponent,
    ValidateComponent,
      StudentFeeComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    SweetAlert2Module.forRoot(),
    AppConfigModule.forRoot({
      publicAPIs: PublicAPI,
      apiPath: APIConstant.basePath
    }),
    AppCommonModule,
    CoreModule,
      NguCarouselModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },

    {
      provide: HTTP_INTERCEPTORS,
      useClass: ResponseInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

